<template>
    <div class="mt-10">  
      <v-card>
        <div class="text-subtitle-1 text-medium-emphasis">Email address</div>
  
        <v-text-field density="compact" placeholder="Email address" prepend-inner-icon="mdi-email-outline"
          variant="outlined" v-model="email"></v-text-field>
  
        <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
          Password
  
          <v-btn class="text-caption text-decoration-none text-blue" variant="text" v-on:click="generatePassword(8)">
            Generate a safe password</v-btn>
        </div>
  
        <v-text-field :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" :type="visible ? 'text' : 'password'"
          density="compact" placeholder="Enter your password" prepend-inner-icon="mdi-lock-outline" variant="outlined"
          @click:append-inner="visible = !visible" v-model="password"></v-text-field>
          <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
            Invite Code
          </div>
          <v-text-field density="compact" placeholder="Invite code" prepend-inner-icon="mdi-account-key"
          variant="outlined" v-model="invite_code"></v-text-field>
  
        <v-btn block class="mb-8" color="blue" size="large" variant="tonal" v-on:click="trySignUp()">
          Sign Up
        </v-btn>
  
        <v-card-text class="text-center">
            <v-btn size="x-small" variant="text" v-on:click="this.$emit('changeTabEvent', 'one')">
                <v-icon icon="mdi-chevron-left"></v-icon> Back to Login 
            </v-btn>
        </v-card-text>
      </v-card>
    </div>
  </template>
  <script>
  import axios from "axios";
  export default {
    data: () => ({
      visible: true,
      email: "",
      password: "",
      invite_code:"",
      
    }),
    methods:{
        trySignUp()
        {
            let SignUpData = {
                Email:this.email,
                Password:this.password,
                Invite:this.invite_code
            }
            if(this.email.length<=4)
            {
              alert("Email too short, try again");
            }else if(this.password.length<=6)
            {
              alert("Password is too short, minimum required length: 6");
            }         
            else if(this.invite_code.length<=6)
            {
              alert("Invite code is invalid or wrong, try again please.");
            }else
            {
              axios.post("https://mediatrunk.net/mobilevids/signup.php", SignUpData)
            .then(response => {                                
                alert(response.data.reason);
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
                alert("There was an error!" +  error);
            });
            }                 
            
        },
        generatePassword(passwordLength) {
        var numberChars = "0123456789";
        var upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        var lowerChars = "abcdefghijklmnopqrstuvwxyz";
        var allChars = numberChars + upperChars + lowerChars;
        var randPasswordArray = Array(passwordLength);
        randPasswordArray[0] = numberChars;
        randPasswordArray[1] = upperChars;
        randPasswordArray[2] = lowerChars;
        randPasswordArray = randPasswordArray.fill(allChars, 3);
        this.password =  this.shuffleArray(randPasswordArray.map(function(x) { return x[Math.floor(Math.random() * x.length)] })).join('');
       },
        shuffleArray(array) {
          for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
          }
          return array;
        }

    }
  }
  </script>