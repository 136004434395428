<template>
    <v-card max-width="1200" class="mx-auto "  >      
      <v-layout>                
        <AppBar />
        <v-main>
          <v-container fluid>
            <h2>Invite</h2>
            
        <div class="text-subtitle-1 text-medium-emphasis">Invite a friend</div>
          
        <v-card>
          <v-card class="elevation-0 pb-5">
            
                  <v-btn color="primary" class="ml-5" v-on:click="dialog=!dialog" :disabled="invites_count<=0">
                    New Invite
                  </v-btn>
                  <p class="ml-5 mt-2"><b>Invites Available:</b> {{invites_count}}</p>
                
            <template>
            <div class="text-center pa-4">
              <v-dialog
                v-model="dialog"
                max-width="400"
                persistent
              >
                

                <v-card
                  prepend-icon="mdi-map-marker"
                  text="Submit the e-mailaddress of the person you would like to invite"
                  title="New Invite"
                >
                <v-card style="padding:5px;" class="evelvation-0">
                <v-text-field                
                label="E-mailaddress"
                prepend-inner-icon="mdi-email-outline"
                variant="outlined"
                hide-details
                single-line
                v-model="invite_email"
              ></v-text-field>
            </v-card>
                  <template v-slot:actions>
                    <v-spacer></v-spacer>

                    <v-btn @click="dialog = false">
                      Cancel
                    </v-btn>

                    <v-btn @click="SendInvite()">
                      Send
                    </v-btn>
                  </template>
                </v-card>
              </v-dialog>
            </div>
          </template>


          </v-card>
                <template v-slot:text>
              <v-text-field
                v-model="search"
                label="Search"
                prepend-inner-icon="mdi-magnify"
                variant="outlined"
                hide-details
                single-line
              ></v-text-field>
            </template>
                <v-data-table style="background-color: rgba(0,0,0,0.0);" 
                  calculate-widths="false"
                  
                    :headers="headers"
                    :items="invites"
                    :search="search"                      
                      
                  ></v-data-table>
              </v-card>
 
                                            
          </v-container>
        </v-main>
      </v-layout>
    </v-card>
  </template>

<script>
import { useUserStore } from '../stores/user'
import AppBar from '../components/AppBar.vue'
import axios from "axios";

export default {
  data: () => ({
    headers: [{ title: 'Used', value: 'used',align: 'start', width: 30},
                  { title: 'Code', value: 'code' },
                  { title: 'Invited User', value: 'invite_mail' },
                  { title: 'Date', value: 'created' }
              ],
        invites: [],
        search:"",
        invite_email:"",
        dialog: false,
        invites_count: 0,
        user: null,
    }),
  components: {
    AppBar
    },
    mounted(){
      this.user = useUserStore();  
      console.log(this.user);
      this.invites_count = this.user.invites_count;
      let UserData = {
               user_id: this.user.id
            }    

      axios.post("https://mediatrunk.net/mobilevids/my_invites.php",UserData)
            .then(response => {
                
                if(response.data.status == 0)
                alert(response.data.reason);
                else
                {
                    console.log(response.data);
                    this.invites = response.data.invites;         
                }
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            });
 
    
    },
    methods:{
    SendInvite(){
      this.dialog = false;
      let InviteData = {
               invite_by: this.user.id,
               code: this.generateSerialNumber(),
               mail: this.invite_email
            } 
              if(this.isValidEmail(this.invite_email))
            {
              axios.post("https://mediatrunk.net/mobilevids/new_invite.php",InviteData)
              .then(response => {
                  
                  if(response.data.status == 0)
                  alert(response.data.reason);
                  else
                  {
                    alert(response.data.reason);    
                  }
              })
              .catch(error => {
                  this.errorMessage = error.message;
                  console.error("There was an error!", error);
              });
            }else
            console.log("Mail is invalid");
            

    },
   isValidEmail(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  },
    generateSerialNumber() {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    return [...Array(3)].map(() => chars[Math.floor(Math.random() * chars.length)]).join('') + '-' +
          [...Array(4)].map(() => chars[Math.floor(Math.random() * chars.length)]).join('') + '-' +
          [...Array(4)].map(() => chars[Math.floor(Math.random() * chars.length)]).join('') + '-' +
          [...Array(3)].map(() => chars[Math.floor(Math.random() * chars.length)]).join('');
  }
  }

}
</script>