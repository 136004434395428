// @ts-check
import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import router from  '../router'
/**
 * Simulate a login
 */
function apiLogin(a, p) {
  if (a === 'ed' && p === 'ed') return Promise.resolve({ isAdmin: true })
  if (p === 'ed') return Promise.resolve({ isAdmin: false })
  return Promise.reject(new Error('invalid credentials'))
}

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    name: useStorage('name',''),
    surname: useStorage('surname',''),
    phone: useStorage('phone',''),
    country: useStorage('country',''),
    city: useStorage('city',''),
    address: useStorage('address',''),
    province: useStorage('province',''),
    postal: useStorage('postal',''),
    email: useStorage('email',''),
    id: useStorage('id',0),
    last_payment: useStorage('last_payment',''),
    expiry_date: useStorage('expiry_date',''),
    billing_id: useStorage("billing_id",0),
    currency: useStorage('currency','CAD'),
    cost: useStorage('cost','7.50'),
    role: useStorage('role',0),
    token: useStorage('token',''),
    invites_count: useStorage('invites_count',''),
    servers:useStorage("servers",[])    
  }),

  actions: {
    signOut() {
      this.$patch({
        name: '',
        email: '',
        role: 0,
      })
      router.push('/login');
    },

    /**
     * Attempt to login a user
     */
    async login(user, password) {
      const userData = await apiLogin(user, password)

      this.$patch({
        name: user,
        ...userData,
      })
    },
  },
})

