<template>
    <v-card max-width="1200" class="mx-auto "  >     

      <v-layout>                
        <AppBar_Admin />
        <v-main>
          <v-container fluid>
          <template>
            <div class="pa-4 text-center">
              <v-dialog v-model="dialog" max-width="600">
                <v-card prepend-icon="mdi-account" title="User Profile">
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field label="BillingID" required v-model="sel_user.billing_id"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field label="Role" required v-model="sel_user.role"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field label="Banned" required v-model="sel_user.banned"></v-text-field>
                      </v-col>                    
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field label="First name" required v-model="sel_user.name"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field label="Surname" v-model="sel_user.surname"></v-text-field>
                      </v-col>
                      <v-col  cols="12" md="4" sm="6">
                        <v-text-field label="Email*" v-model="sel_user.mail"></v-text-field>
                      </v-col>
                      <v-col  cols="12" md="4" sm="6">
                        <v-text-field label="Last Payment" v-model="sel_user.last_payment"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field label="Access" required v-model="sel_user.access"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field label="Invites Available" required v-model="sel_user.invites_available"></v-text-field>
                      </v-col>                                            
                    </v-row>
                    <v-row dense>
                      <label>New password</label>
                    </v-row>
                    <v-row dense>                      
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field label="Password*" required v-model="sel_user.password"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="6">
                        <v-btn text="Generate Password"  @click="sel_user.password = GeneratePassword(8)" ></v-btn>
                      </v-col>
                    </v-row>                    
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn  text="Close"  variant="plain" @click="dialog = false"></v-btn>
                    <v-btn color="primary" text="Save" variant="tonal"  @click="SaveDetails()"></v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialog_server" max-width="1600">
                <v-card prepend-icon="mdi-account" title="User Profile">
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" md="4" sm="6">
                        <h3>Select a server</h3>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col >
                        <v-data-table                          
                          :items="this.servers"
                          :headers="this.serverheaders"
                          item-value="Id"
                          select-strategy="single"
                          v-model="selected_server"
                          return-object
                          show-select
                        ></v-data-table>
                      </v-col>                                 
                    </v-row>             
                    <v-row dense>
                      <label>Account password</label>
                    </v-row>
                    <v-row dense>                      
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field label="Password*" required v-model="selected_server_user_password"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="6">
                        <v-btn text="Generate Password"  @click="selected_server_user_password = GeneratePassword(8)" ></v-btn>
                      </v-col>
                    </v-row>              
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn  text="Close"  variant="plain" @click="dialog_server = false"></v-btn>
                    <v-btn color="primary" text="Save" variant="tonal"  @click="ChangeUserServer()"></v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </template>

            <v-data-table
                :search="search"
                v-model:expanded="expanded"
                :headers="this.userHeaders"
                :items="this.users"
                item-value="user_id"
                single-expand
                show-expand
                @update:expanded="loadDetails"
            >
            
                <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Users</v-toolbar-title>
                    <v-text-field
                    v-model="search"
                    label="Search"
                    prepend-inner-icon="mdi-magnify"
                    variant="outlined"
                    hide-details
                    single-line
                    ></v-text-field>
                </v-toolbar>
                
                </template>
                <template v-slot:expanded-row="{ columns, item }">
                <tr>
                    <td :colspan="columns.length">
                    <v-card color="surface-variant" class="mt-5 mb-5">
                        <template v-slot:title>
                            {{ item.mail }}
                        </template>

                        <template v-slot:subtitle>
                         Assigned To: {{ item.server_name }}                         
                        </template>

                        <template v-slot:text>
                        <p>IP Address: {{item.last_ipaddress}}</p>
                        <p v-if="item.access==1">Access: Yes</p>                        
                        <p v-else>Access: No</p>
                        <h4 class="mt-5">Jellyfin Info</h4>
                        <v-divider></v-divider>
                        <p>Jellyfin Username: {{ item.jellyfin_username }}</p> 
                        <p>Jellyfin ID: {{ item.jellyfin_userid }}</p> 
                        <p>Disabled: {{isUserDisabled}}</p>
                        <p>Last Login: {{ LastLogin }}</p>
                        

                        </template>
                        <template v-slot:actions>
                            <v-btn  class="ms-4 text-white" color="blue-darken-4" text="Change Server"
                            rounded="0"
                            variant="flat" @click="SelectServer(item)"></v-btn>
                            <v-btn  class="ms-4 text-white" color="blue-darken-4" text="Impersonate"
                            @click="Impersonate(item)"
                            rounded="0"
                            variant="flat"></v-btn>
                            
                            <v-btn  class="ms-4 text-white" color="blue-darken-4" text="Edit Details"
                            @click="dialog = true"
                            rounded="0"
                            variant="flat"></v-btn>
                        </template>
                    </v-card>                  
                    </td>
                </tr>
                </template>
            </v-data-table>   

            
          </v-container>
        </v-main>
      </v-layout>
    </v-card>
  </template>

<script>
import { useUserStore } from '../../stores/user'
import AppBar_Admin from '../../components/AppBar_Admin.vue'
import axios from "axios";
import router from '../../router'


export default {
  data: () => ({
      visible: false,
      dialog: false,
      dialog_server: false,
      sel_user: null,
      selected_server:[],
      selected_server_user_password:"",
      search: '',
      password: "",
      expanded: [],
      new_password: "",
      users: [],
        userHeaders: [
          {
            title: 'UserID',
            align: 'start',            
            sortable: false,
            key: 'user_id',
          },          
          { title: 'E-mail', key: 'mail' },
          { title: 'Access', key: "access_phonetic"},
          { title: 'Billing ID', key: 'billing_id' },          
          { title: 'Renewed', key: 'last_payment' },

          
          
          { title: '', key: 'data-table-expand' },
        ],
        servers:[],
      serverheaders: [
          {
            title: 'Id',
            align: 'start',            
            sortable: false,
            key: 'Id',
          },
          { title: 'Name', key: 'Name' },
          { title: 'URL', key: 'API_ENDPOINT' },
          { title: 'API_KEY', key: 'API_KEY' },
          { title: 'Total Users', key: 'Total_Users' },
      ]
    }),
  setup() {
    const user = useUserStore();        
    
    return{user}

  },  
  computed: {
    isUserDisabled()
    {
      if(this.sel_user.jfin_data != undefined)
      return this.sel_user.jfin_data.Policy.IsDisabled ? "Yes": "No";
      else return "N/A";
    },
    LastLogin()
    {
      if(this.sel_user.jfin_data != undefined)
      return this.FormatDateFromISOString(this.sel_user.jfin_data.LastLoginDate);
      else return "N/A";
    }
  },
  components: {
    AppBar_Admin
    },
    mounted(){
        this.getUsers();
        this.getServers();
    },
    methods:{
    ChangeUserServer()
    {
      console.log(this.selected_server);
    if(this.selected_server_user_password!="" && this.sel_user != undefined && this.selected_server != undefined)
    {
      let UserData = {
                User:this.sel_user,            
                Server: this.selected_server,
                User_Password: this.selected_server_user_password
            }                        
            axios.post("https://mediatrunk.net/mobilevids/admin/set_user_server.php", UserData)
            .then(response => {   
              console.log(response.data);
              alert(response.data.reason);           
                this.dialog = false;
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            });
    }else
    alert("Something is missing, did you fill in new password? Is a server selected?");
     
    },
    FormatDateFromISOString(isoString) {
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    },

    GeneratePassword(passwordLength) {
      var numberChars = "0123456789";
      var upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var lowerChars = "abcdefghijklmnopqrstuvwxyz";
      var allChars = numberChars + upperChars + lowerChars;
      var randPasswordArray = Array(passwordLength);
      randPasswordArray[0] = numberChars;
      randPasswordArray[1] = upperChars;
      randPasswordArray[2] = lowerChars;
      randPasswordArray = randPasswordArray.fill(allChars, 3);
      return this.shuffleArray(randPasswordArray.map(function(x) { return x[Math.floor(Math.random() * x.length)] })).join('');
      },
      shuffleArray(array) {
          for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
          }
          return array;
        },
  
    SaveDetails()
    {
                       
            axios.post("https://mediatrunk.net/mobilevids/admin/save_user.php", this.sel_user)
            .then(response => {     
              alert(response.data.reason);              
                this.dialog = false;
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            });
    },
    SelectServer(item)
    {
      this.sel_user = item;
      this.dialog_server = true;
    },
    Impersonate(item)
    {
      let data = {
              user_id: item.user_id
            }                        
            axios.post("https://mediatrunk.net/mobilevids/get_user.php", data)
            .then(response => {
                
                if(response.data.status == 0)
                alert(response.data.reason);
                else
                {                    
                    const user = useUserStore();
                    user.$patch({email: response.data.mail,
                                 name: response.data.name,
                                 surname: response.data.surname,
                                 phone:response.data.phone,
                                 country:response.data.country,
                                 city:response.data.city,
                                 address: response.data.address,
                                 province: response.data.province,
                                 postal:response.data.postal,
                                 last_payment:  response.data.last_payment,
                                 expiry_date: response.data.expiry_date,
                                 id:response.data.id,                                 
                                 billing_id: response.data.billing_id,
                                 
                                 role: response.data.role,
                                 token: response.data.token,
                                 invites_count: response.data.invites_count,
                                 servers:response.data.servers});                    
                                 router.push('/');        
                }
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            });

      
     
    },
    loadDetails(item)
    {
      this.users.forEach((u) => {
        if(u.user_id == item)
        {
          this.sel_user = u;
          this.sel_user.password = "";

        axios.post("https://mediatrunk.net/mobilevids/admin/get_jfin_user.php", u)
              .then(response => {     
                console.log(response.data);              
                this.sel_user.jfin_data = response.data;              
              })
              .catch(error => {
                  this.errorMessage = error.message;
                  console.error("There was an error!", error);
              });              
        }        
         
      });
      
    },        
    Launch(URL){
      window.open(URL,'_blank');                    
    },
    getUsers()
    {
        let LoginData = {
            Email:this.user.email,
            Password:this.password,
            NewPassword: this.new_password
        }                        
        axios.post("https://mediatrunk.net/mobilevids/admin/users.php", LoginData)
        .then(response => {
            this.users = response.data.users;                          
        })
        .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
        });
    },
    getServers()
    {                      
        axios.post("https://mediatrunk.net/mobilevids/admin/servers.php")
        .then(response => {
            this.servers = response.data.servers;  
                            
        })
        .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
        });
    }
  }

}
</script>